<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="6">
					试用期员工(共<b>{{ totalItems }}</b
					>人)
				</a-col>
				<a-col :span="5" style="float:right">
					<a-input-search
						style="width: 100%"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>

				<a-col :span="4" style="float:right">
					<a-tree-select
						v-model="DepartmentId"
						style="width: 100%"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						allow-clear
						@change="SelectDepartmentTree"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="4" style="float:right">
					<a-tree-select
						@change="companyChange"
						:placeholder="l('Company')"
						style="width: 100%"
						allow-clear
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="userimage" slot-scope="text, record" v-if="record.image && record.imgType">
					<a-avatar icon="user" :src="PicturePath + record.image + record.imgType" />
				</span>
				<span slot="actions" slot-scope="text, record">
					<a @click="handleFormal(record)" v-if="isGranted('handleFormal')">
						办理转正
					</a>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { CompanyServiceProxy, DepartmentServiceProxy, InternshipServiceServiceProxy } from '@/shared/service-proxies';
import moment from 'moment';
import { AppConsts } from '../../../abpPro/AppConsts';
import EditFormal from './edit-formal/edit-formal';
import ModalHelper from '@/shared/helpers/modal/modal-helper';
import { abpService } from '@/shared/abp';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'internship',
	mixins: [AppComponentBase],
	components: { EditFormal },
	data() {
		return {
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('Company'),
					dataIndex: 'company',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'company' },
				},
				{
					title: this.l('Department'),
					dataIndex: 'derpanment',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'derpanment' },
				},
				{
					title: this.l('OrgPost'),
					dataIndex: 'post',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'post' },
				},
				{
					title: this.l('EmpType'),
					dataIndex: 'staffTypeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'staffTypeName' },
				},
				{
					title: this.l('HireDate'),
					dataIndex: 'hireDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: this.l('RegularDate'),
					dataIndex: 'regularDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularDate' },
				},
				{
					title: this.l('ProbationMonth'),
					dataIndex: 'probationMonth',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'probationMonth' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: '100px',
				},
			],
			tableData: [],
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			PicturePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
		};
	},
	created() {
		this._internshipServiceServiceProxy = new InternshipServiceServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.companyChange(this.CompanyId);
		}
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		getData() {
			this.loading = true;
			this._internshipServiceServiceProxy
				.getInternshipList(
					this.DepartmentId,
					this.CompanyId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ' ';
						item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.companyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
			if (this.CompanyId) {
				this.getDepTreeData();
			} else {
				this.DepartmentId = undefined;
				this.DepTreeData = [];
			}
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepartmentId = undefined;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.DepartmentId = e;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		/**
		 *办理转正
		 */
		handleFormal(record) {
			ModalHelper.create(
				EditFormal,
				{
					empId: record.id,
				},
				{
					width: '500px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
	},
};
</script>

<style scoped></style>
